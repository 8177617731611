<script setup>
import {computed, watch, ref} from "vue";
import {useForm, usePage} from "@inertiajs/inertia-vue3";
import BoInput from "@/Components/Input.vue";
import InputAppend from "@/Components/InputAppend.vue";
import {notify} from "@kyvg/vue3-notification";
const emit = defineEmits(['cancelEditing']);
const props = defineProps({
    itemToEdit: {
        type: Object,
        default: null
    },
});
const form = useForm({
    name: '',
    slug: '',
    weight: 0,
    permissions:[],
});
const editMode = computed(() => props.itemToEdit !== null);

/**
 * Data for the datatable - List of administrators
 * @type {ComputedRef<*>}
 */
const permissions = computed(() => usePage().props.value.permissions );
/**
 * Watcher for params
 * Make an Inertia request with cleaned params
 */

watch(() => props.itemToEdit, (newItemToEdit) => {
    if (newItemToEdit !== null) {
        form.defaults({
            name: newItemToEdit.name,
            slug: newItemToEdit.slug,
            weight: newItemToEdit.weight,
            permissions:getCurrentPermissionsId(),
        })
    } else {
        form.defaults({
            name: '',
            slug: '',
            weight: 0,
            permissions:[],
        })

    }
    form.clearErrors();
    form.reset();
});
const isRoleChecked = (permission_id) => {
    if(!props.itemToEdit){
        return false;
    }
    const permissions_id_role = getCurrentPermissionsId();
    return permissions_id_role.includes(permission_id);
}
const getCurrentPermissionsId = () => {
    if(!props.itemToEdit){
        return [];
    }
    return props.itemToEdit.permissions.map(permission => permission.id);
}

const submit = () => {
    if (props.itemToEdit === null) {
        form.post(route('admin.roles.store'), {
            onSuccess: () => {
                form.defaults({
                    name: '',
                    slug: '',
                    weight: 0,
                    permissions:[],
                })
                form.reset();
                const success_msg = usePage().props.value.flash.success;
                if (success_msg) {
                    notify({
                        title: usePage().props.value.language['ui.role.Added'],
                        text: usePage().props.value.language['ui.role.SuccessMsgAdded'],
                        type: "success",
                    })
                }

            },
        })
    } else {
        form.put(route('admin.roles.update', props.itemToEdit.id), {
            onSuccess: () => {
                form.defaults({
                    name: '',
                    slug: '',
                    weight: 0,
                    permissions:[],
                })
                form.reset();
                const success_msg = usePage().props.value.flash.success;
                if (success_msg) {
                    notify({
                        title: usePage().props.value.language['ui.role.Modified'],
                        text: usePage().props.value.language['ui.role.SuccessMsgModified'],
                        type: "success",
                    })
                }
                emit('cancelEditing');
            },
        })
    }
};

</script>
<template>
    <div class="card animate">
        <div class="card-header">
            <h3 class="card-title">
                <span v-if="editMode">
                    <i class="fas fa-pen-alt"></i>
                    {{ __('ui.role.EditARole') }}
                </span>
                <span v-else>
                    <i class="fas fa-plus-circle"></i>
                    {{ __('ui.role.AddARole') }}
                </span>
            </h3>
        </div>
        <form @submit.prevent="submit">
            <div class="card-body">
                <div class="form-group">
                    <label for="name">{{ __('ui.role.RoleName') }} <sup class="text-danger">*</sup></label>
                    <div class="input-group" :class="{'is-invalid': form.errors.name}">
                        <BoInput id="name" :placeholder="__('ui.role.RoleName')" type="text" v-model="form.name"
                                 :disabled="itemToEdit ? !itemToEdit.can_be_updated : false"
                                 :class="{'is-invalid': form.errors.name}" autocomplete="name" autofocus required />
                        <InputAppend icon="fa-signature"/>
                    </div>
                    <div class="invalid-feedback" v-if="form.errors.name">
                        {{ form.errors.name }}
                    </div>
                </div>
                <div class="form-group">
                    <label for="slug">{{ __('ui.role.InternalNameSlug') }} <sup class="text-danger">*</sup></label>
                    <div class="input-group" :class="{'is-invalid': form.errors.slug}">
                        <BoInput type="text" autocomplete="slug" class="form-control"
                                 :disabled="itemToEdit ? !itemToEdit.can_be_updated : false"
                                 :class="{'is-invalid': form.errors.slug}" id="slug" v-model="form.slug"
                                 :placeholder="__('ui.role.InternalNameSlug')"  required/>
                        <InputAppend icon="fa-database"/>
                    </div>
                    <div class="invalid-feedback" v-if="form.errors.slug">
                        {{ form.errors.slug }}
                    </div>
                </div>
                <div class="form-group">
                    <label for="weight">{{ __('ui.role.Weights') }} <sup class="text-danger">*</sup></label>
                    <div class="input-group" :class="{'is-invalid': form.errors.weight}">
                        <BoInput type="number"  class="form-control"
                                 min="0"
                                 max="255"
                                 :disabled="itemToEdit ? !itemToEdit.can_be_updated : false"
                                 :class="{'is-invalid': form.errors.weight}" id="weight" v-model="form.weight"
                                 :placeholder="__('ui.role.Weights')"  required/>
                        <InputAppend icon="fa-weight-hanging"/>
                    </div>
                    <small id="emailHelp" class="form-text text-muted">{{ __('ui.role.AdministratorManager') }}</small>
                    <div class="invalid-feedback" v-if="form.errors.weight">
                        {{ form.errors.weight }}
                    </div>
                </div>
                <div class="form-group">
                    <strong>{{ __('ui.role.Permission') }}<span v-if="permissions.length > 1">s</span></strong>
                    <div class="form-check pl-0" :class="{'is-invalid': form.errors.permissions}">
                        <div class="icheck-primary"  v-for="(permission,index) in $page.props.permissions" :key="index">
                            <input type="checkbox" :disabled="props.itemToEdit && props.itemToEdit.slug==='super-admin' && permission.slug==='manage-roles-permissions'" :id="`permission_${permission.slug}`" v-model="form.permissions" :checked="isRoleChecked(permission.id)" :value="permission.id">
                            <label class="form-check-label" :for="`permission_${permission.slug}`">
                                {{ __(permission.name) }}
                            </label>
                        </div>
                    </div>

                    <div class="invalid-feedback" v-if="form.errors.permissions">
                        {{ form.errors.permissions }}
                    </div>
                </div>
            </div>
            <div class="card-footer">
                <div class="row">
                    <div class="col-sm-6" v-if="editMode">
                        <button type="button" @click="$emit('cancelEditing')" class="btn btn-block btn-default"><i
                            class="fas fa-undo"></i> {{ __('ui.administrator.Cancel') }}
                        </button>
                    </div>
                    <div :class="{'col-sm-12': !editMode, 'col-sm-6': editMode}">
                        <button type="submit" :disabled="form.processing" class="btn btn-block btn-primary"><i
                            class="fas fa-save"></i> {{ __('ui.administrator.Register') }}
                        </button>
                    </div>
                </div>
            </div>
        </form>
    </div>
</template>

