import {IdeleLocalIndexedDB} from "@/services/synchro_data/ideleIndexedDb.js";

const synchronizeExploitants = async (db) => {
    return new Promise((resolve, reject) => {
        console.log("      Début de synchronisation des exploitants Serveur => Local...");
        axios.get(route("admin.administrators.getRelatedExploitants"))
            .then(res => {
                let nb_added = 0;
                const exploitants = res.data,
                    tx = IdeleLocalIndexedDB.makeTX(db, IdeleLocalIndexedDB.exploitantStoreName, 'readwrite');
                tx.oncomplete = (ev) => {
                    console.log(`      Fin de synchronisation des exploitants Serveur => Local : ${nb_added} exploitant(s) synchronisé(s)`);
                    resolve({nb_added});
                };
                const store = tx.objectStore(IdeleLocalIndexedDB.exploitantStoreName);

                // On efface tous les exploitants enregistrés en local
                const req = store.clear();

                // On boucle sur les exploitants reçus
                exploitants.forEach((exploitant) => {
                    const req = store.add(exploitant);
                    req.onsuccess = (ev) => nb_added++;
                    req.onerror = (err) => {
                        reject(new Error('Error inserting exploitant'));
                    };
                })

            })
            .catch(function (error) {
                reject(new Error(error))
            })

    })
}
export {
    synchronizeExploitants
}
