<template>
    <div class="card animate">
        <div class="card-header">
            <h3 class="card-title"><i class="fas fa-list"></i> Liste des ateliers personnalisés de
                <strong>{{ user.organisme_name }}</strong></h3>
        </div>
        <div class="card-body">
            <div id="custom_atelier_wrapper" class="dataTables_wrapper dt-bootstrap4"
                 v-if="custom_ateliers.data.length">
                <div class="row">
                    <div class="col-sm-12">
                        <div class="table-responsive">
                            <table id="custom_ateliers"
                                   class="table table-bordered  dataTable dtr-inline table-sm"
                                   role="grid" aria-describedby="custom_ateliers_info">
                                <thead>
                                <tr role="row">
                                    <th tabindex="0"
                                        aria-controls="custom_ateliers">
                                        Parent
                                    </th>
                                    <th tabindex="0"
                                        aria-controls="custom_ateliers">
                                        Atelier
                                    </th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr v-for="(atelier, index) in custom_ateliers.data" :key="index">
                                    <td>
                                        <ol class="atelier-breadcrumb list-unstyled list-inline mb-0">
                                            <li class="list-inline-item breadcrumb-item mr-0"
                                                v-for="(item, index_item) in findLabelsById(atelier.atelier_FK)"
                                                :key="index_item">
                                                <em class="text-muted">{{ item }}</em>
                                            </li>
                                        </ol>
                                    </td>
                                    <td>{{ atelier.label }}</td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-12 col-md-5">
                        <div class="dataTables_info mb-2 mb-sm-0" id="diagnostics_info" role="status"
                             aria-live="polite">{{ __('ui.administrator.DisplayOf') }} {{ custom_ateliers.from }}
                            {{ __('ui.administrator.To') }} {{ custom_ateliers.to }} {{ __('ui.administrator.On') }}
                            {{ custom_ateliers.total }}
                            {{ custom_ateliers.total > 1 ? __('ui.administrator.Entries') : __('ui.administrator.Entry') }}
                        </div>
                    </div>
                    <div class="col-sm-12 col-md-7">
                        <Pagination class="mt-2 mb-sm-0" id="diagnostics_paginate" :items="custom_ateliers"/>
                    </div>
                </div>
            </div>
            <div class="jumbotron" v-else>
                <p class="text-center mb-0 text-uppercase">Actuellement aucun atelier personnalisé</p>
            </div>
        </div>
    </div>
</template>

<script setup>
import {usePage} from "@inertiajs/inertia-vue3";
import {has, some} from "lodash";
import {computed} from "vue";
import Pagination from "@/Components/Pagination.vue";

const user = usePage().props.value.auth.user;
const custom_ateliers = computed(() => usePage().props.value.custom_ateliers)
const standard_ateliers = usePage().props.value.standard_ateliers;

/**
 * Retrouve l'arborescence des parents
 * @param targetId
 * @returns {*[]}
 */
function findLabelsById(targetId) {
    let labels = [];

    function helper(node, targetId, parentLabels) {
        if (node.id === targetId) {
            labels = [...parentLabels, node.label];
            return true;
        }
        if (has(node, 'children')) {
            return _.some(node.children, child => helper(child, targetId, [...parentLabels, node.label]));
        }
        return false;
    }

    some(standard_ateliers, atelier => helper(atelier, targetId, []));
    return labels;
}


</script>
