<script setup>
import {computed, ref} from "vue";
import Input from "@/Components/Input.vue";
import {generate_hash_log, encrypt_log_for_bo} from "@/Helpers/dowinoEncryptLog.js";
import {onEnterOpacity, onBeforeEnterOpacity} from "@/Helpers/animations";

const example_log = {
    "player": {
        "lastname": "Chappard",
        "firstname": "Aurélien"
    },
    "score": {
        "chapter1": 1111,
        "chapter2": 2222,
        "chapter3": 3333,
        "chapter4": 4444
    }
}
/**
 * Passphrase stockée dans le fichier .env du projet
 * @type {ComputedRef<unknown>}
 */
const passphrase = computed(() => {
    const passphrase_from_env = import.meta.env.DOWINO_LOG_KEY
    if(passphrase_from_env && passphrase_from_env.startsWith('base64:')){
        return passphrase_from_env.slice(7);
    }
   return passphrase_from_env;
} )

const logToEncrypt = ref(JSON.stringify(example_log));
const logEncrypted = computed(() => encrypt_log_for_bo(logToEncrypt.value, passphrase.value) )
const hash = computed(() => generate_hash_log(logToEncrypt.value) )

</script>

<template>
    <transition @before-enter="onBeforeEnterOpacity"
                @enter="onEnterOpacity"
                appear mode="out-in">
    <div class="row">
        <div class="col-sm-6">
            <div class="card animate">
                <div class="card-header">
                    <h3 class="card-title"><i class="fas fa-list"></i> {{ __('ui.frontLogs.LogToSend') }}</h3>
                </div>
                <div class="card-body">
                    <textarea rows="7" class="form-control" v-model="logToEncrypt"/>
                </div>
            </div>
        </div>
        <div class="col-sm-6">
            <div class="card animate">
                <div class="card-header">
                    <h3 class="card-title"><i class="fas fa-list"></i> {{ __('ui.frontLogs.Cryptage') }}</h3>
                </div>
                <div class="card-body">
                    <label for="logEncrypted">{{ __('ui.frontLogs.EncryptedLog') }}</label>
                    <textarea id="logEncrypted" disabled rows="7" class="form-control mb-3" v-model="logEncrypted"/>
                    <div class="alert alert-warning" v-if="passphrase === undefined">
                        Il semble qu'il n'y ait pas de clé définie pour le crypatge des logs dnas le fichier <code>.env</code>.
                        Veuillez executer la commande suivante pour définir une clé : <code>php artisan dowino:log_key</code>
                    </div>
                    <label for="hash">{{ __('ui.frontLogs.DataHash') }}</label>
                    <Input id="hash" disabled v-model="hash"/>
                </div>
            </div>
        </div>
    </div>
    </transition>

</template>
