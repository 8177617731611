<script setup>

</script>

<template>
    <div class="container-fluid">
        <h1>IDELE - FRONT_END</h1>
        <hr>
        <section class="content">
            <slot/>
        </section>
    </div>
</template>

<style scoped>

</style>
