import {IdeleLocalIndexedDB} from "./ideleIndexedDb.js";

const synchronizeDiagnostics = async (db) => {
    console.log("      Début de synchronisation des diagnostics Serveur => Local...");
    return new Promise((resolve, reject) => {
        axios.get(route('admin.diagnotics.getAllForLocalSync'))
            .then(res => {
                let nb_added = 0;
                const diagnostics = res.data,
                    tx = IdeleLocalIndexedDB.makeTX(db, IdeleLocalIndexedDB.diagnosticStoreName, 'readwrite');
                tx.oncomplete = (ev) => {
                    console.log(`      Fin de synchronisation des diagnostics Serveur => Local : ${nb_added} diagnostic(s) synchronisé(s)`);
                    resolve({nb_added});
                };
                const store = tx.objectStore(IdeleLocalIndexedDB.diagnosticStoreName);

                // On efface tous les diagnostics enregistrés en local
                store.clear();

                // On boucle sur les diagnostics reçus
                diagnostics.forEach( (diagnostic) =>{
                    const req = store.add(diagnostic);
                    req.onsuccess = (ev) => nb_added++;
                    req.onerror = (err) => {
                        reject(new Error('Error inserting diagnostic'));
                    };
                } )
            })
            .catch(function (error) {
                reject(new Error(error))
            })
    });
}

/**
 *
 * @param db
 * @param id
 * @returns {Promise<unknown>}
 */
const destroyLocalDiagnostic = async (db, id) => {
    return new Promise((resolve, reject) => {
        console.log(`      Tentative de suppression du diagnostic local ${id}`);
        const transaction = IdeleLocalIndexedDB.makeTX(db, IdeleLocalIndexedDB.diagnosticStoreName, 'readwrite');
        const store = transaction.objectStore(IdeleLocalIndexedDB.diagnosticStoreName);
        const deleteRequest = store.delete(id);

        deleteRequest.onsuccess = (event) => {
            console.log(`      Tentative réussie`);
            resolve();
        };
        deleteRequest.onerror = (event) => {
            console.log(`      Tentative échouée`);
            resolve(event.target.error);
        };
    });
}

const destroyDistantDiagnostic = async(id) => {
    return new Promise((resolve,reject) => {
        console.log(`      Tentative de suppression du diagnostic distant ${id}`);
        axios.delete(route('admin.diagnotics.destroy', id))
            .then(response => {
                console.log(`      Tentative réussie`);
                resolve(response.data);
            })
            .catch(error => {
                console.log(`      Tentative échouée`);
                reject(error); // Rejeter la promesse avec l'erreur rencontrée
            });
    })
}

export {synchronizeDiagnostics, destroyDistantDiagnostic, destroyLocalDiagnostic}
