<script setup>
import { Link } from '@inertiajs/inertia-vue3';
import {computed, onMounted} from "vue";
const emit = defineEmits(['paginate']);
const props = defineProps({
    items: {
    }
});
const links = computed( () => props.items.links )
const showingPagination = computed( () => props.items.total >  props.items.per_page)

</script>

<template>
    <div class="dataTables_paginate paging_simple_numbers" v-if="showingPagination">
        <ul class="pagination pagination-sm">
            <li class="paginate_button page-item" :class="{'disabled' : !link.url, 'active': link.active}" v-for="(link, index) in links" :key="index">
                <Link @click="$emit('paginate')" :href="link.url" preserve-scroll preserve-state class="page-link" tabindex="0"><span v-html="link.label"/></Link>
            </li>
        </ul>
    </div>
</template>



