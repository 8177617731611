<script setup>
import {computed, watch, ref, onMounted} from "vue";
import {useForm, usePage} from "@inertiajs/inertia-vue3";
import BoInput from "@/Components/Input.vue";
import InputAppend from "@/Components/InputAppend.vue";
import {notify} from "@kyvg/vue3-notification";
import BoDowinoDateRangePicker from "@/Components/DateRangePicker.vue";
import additionnalUserMetasDefinition from '../datas/user_metas.json';
import {get_departements_list} from "@/Pages/Administrators/helpers/departements.js";
import {build_all_organisme_options_list} from "@/Pages/Administrators/helpers/organisme.js";

const emit = defineEmits(['cancelEditing']);
const showNewPasswordBox = ref(false);
const additional_user_metas = ref([]);
const account_type = computed(() => {
    if(form.role){
        const role = usePage().props.value.roles.filter( (role ) => role.id === form.role).shift();
        return "<strong>"+role.name.toLowerCase()+"</strong>";
    }

} );
const dynamic_options = ref([]);
const editMode = computed(() => props.itemToEdit !== null);

const props = defineProps({
    itemToEdit: {
        type: Object,
        default: null
    },
});

const form = useForm({
    firstname: '',
    lastname: '',
    email: '',
    password: '',
    role: '',
    meta_organisme_name: '',
    meta_exploitant_organisme_id: null,
    meta_conseiller_organisme_id: null,
    meta_referent_validity_date: null,
    meta_conseiller_validity_date: null,
    meta_nb_paid_accounts: null,
    meta_nb_free_accounts: null,
    meta_dpt_exploitation: null,
    meta_conseiller_is_paid_account: 0,
    meta_conseiller_is_active_account: 1
});

onMounted(() => {
    // liste des options dispo pour les organismes
    const data_organisme = build_all_organisme_options_list(usePage().props.value.organismes_list);
    if(data_organisme.length === 1){
        form.meta_exploitant_organisme_id = data_organisme[0].value;
        form.meta_conseiller_organisme_id = data_organisme[0].value;
    }
    // console.log(data_organisme);
    dynamic_options.value.options_organismes = data_organisme;

    // Liste des options disponibles pour le compte gratuit/payant
    dynamic_options.value.options_is_paid_account = [
        {label : "Compte gratuit", value:0},
        {label : "Compte payant", value:1},
    ];
    // Liste des options disponibles pour le compte actif/inactif
    dynamic_options.value.options_is_active_account = [
        {label : "Compte actif", value:1},
        {label : "Compte désactivé", value:0},
    ];

    const roles_available = usePage().props.value.roles;
    if(roles_available.length === 1){
        const role = roles_available[0];
        form.role = role.id;
    }

})

watch(() => usePage().props.value.organismes_list, (new_list) => build_all_organisme_options_list(new_list))

/**
 * Au changement d'un rôle dans les radios button, on génère les champs correspondants dynamiquement
 */
watch(() => form.role, (new_role_id) => {
    if (new_role_id) {
        const roles_list = usePage().props.value.roles;
        const selected_role_slug = roles_list.find(role => role.id === new_role_id).slug;
        additional_user_metas.value = additionnalUserMetasDefinition[selected_role_slug];

        if (new_role_id === 5) {
            if (!dynamic_options.value.options_dpt) {
                get_departements_list().then(result => dynamic_options.value.options_dpt = result)
            }
        }
    } else {
        additional_user_metas.value = [];
    }

})

/**
 * Watcher for params
 * Make an Inertia request with cleaned params
 */

watch(() => props.itemToEdit, (newItemToEdit) => {
    if (newItemToEdit !== null) {
        const metas = newItemToEdit.user_metas;
        const meta_organisme_name = metas.find((meta) => meta.meta_key === "organisme_name"),
            meta_validity_date_start = metas.find((meta) => meta.meta_key === "validity_date_start"),
            meta_validity_date_end = metas.find((meta) => meta.meta_key === "validity_date_end"),
            meta_validity = meta_validity_date_start && meta_validity_date_end ? [meta_validity_date_start.meta_value, meta_validity_date_end.meta_value] : null,
            meta_nb_paid_accounts = metas.find((meta) => meta.meta_key === "nb_paid_accounts"),
            meta_nb_free_accounts = metas.find((meta) => meta.meta_key === "nb_free_accounts"),
            meta_dpt_exploitation = metas.find((meta) => meta.meta_key === "dpt_exploitation"),
            meta_exploitant_organisme_id = metas.find((meta) => meta.meta_key === "organisme_id"),
            meta_conseiller_organisme_id = metas.find((meta) => meta.meta_key === "organisme_id"),
            meta_conseiller_is_active_account = metas.find((meta) => meta.meta_key === "is_active_account") ;
        //console.log(meta_conseiller_is_active_account);
        const user_role = getCurrentUserRoleId();


        form.defaults({
            firstname: newItemToEdit.firstname,
            lastname: newItemToEdit.lastname,
            email: newItemToEdit.email,
            password: newItemToEdit.password,
            role: user_role,
            meta_organisme_name: meta_organisme_name ? meta_organisme_name.meta_value : '',
            meta_exploitant_organisme_id: meta_exploitant_organisme_id ? meta_exploitant_organisme_id.meta_value : '',
            meta_conseiller_organisme_id: meta_conseiller_organisme_id ? meta_conseiller_organisme_id.meta_value : '',
            meta_referent_validity_date: meta_validity,
            meta_conseiller_validity_date: meta_validity,
            meta_nb_paid_accounts: meta_nb_paid_accounts ? parseInt(meta_nb_paid_accounts.meta_value) : null,
            meta_nb_free_accounts: meta_nb_free_accounts ? parseInt(meta_nb_free_accounts.meta_value) : null,
            meta_dpt_exploitation: meta_dpt_exploitation ? meta_dpt_exploitation.meta_value : null,
            meta_conseiller_is_active_account : meta_conseiller_is_active_account ? meta_conseiller_is_active_account.meta_value : 1,
        })
    } else {
        form.defaults({
            firstname: '',
            lastname: '',
            email: '',
            password: '',
            role: '',
            meta_organisme_name: '',
            meta_exploitant_organisme_id: null,
            meta_conseiller_organisme_id: null,
            meta_referent_validity_date: null,
            meta_conseiller_validity_date: null,
            meta_nb_paid_accounts: null,
            meta_nb_free_accounts: null,
            meta_dpt_exploitation: null,
            meta_conseiller_is_paid_account: 0,
            meta_conseiller_is_active_account: 1
        })

    }
    showNewPasswordBox.value = false;
    form.clearErrors();
    form.reset();
});

const cancelNewPasswordBox = () => {
    showNewPasswordBox.value = !showNewPasswordBox.value;
    form.defaults('password', '');
    form.reset('password');
    form.clearErrors('password');
}

const getCurrentUserRoleId = () => {
    if (!props.itemToEdit) {
        return '';
    }
    return props.itemToEdit.roles[0].id;
}

const maxRoleCurrentUser = computed(() => usePage().props.value.auth.user.maxRoleWeight);
const submit = () => {
    if (props.itemToEdit === null) {
        form.post(route('admin.administrators.register'), {
            onSuccess: () => {
                form.defaults({
                    firstname: '',
                    lastname: '',
                    email: '',
                    password: '',
                    role: '',
                })
                form.reset();
                const success_msg = usePage().props.value.flash.success;
                if (success_msg) {
                    notify({
                        title: usePage().props.value.language['ui.administrator.Added'],
                        text: usePage().props.value.language['ui.administrator.SuccessMsgAdded'],
                        type: "success",
                    })
                }

            },
        })
    } else {
        form.put(route('admin.administrators.update', props.itemToEdit.id), {
            onSuccess: () => {
                showNewPasswordBox.value = false;
                form.defaults({
                    firstname: '',
                    lastname: '',
                    email: '',
                    password: '',
                    role: '',
                })
                form.reset();
                const success_msg = usePage().props.value.flash.success;
                if (success_msg) {
                    notify({
                        title: usePage().props.value.language['ui.administrator.Modified'],
                        text: usePage().props.value.language['ui.administrator.SuccessMsgModified'],
                        type: "success",
                    })
                }
                emit('cancelEditing');
            },
        })
    }
};

</script>
<template>
    <div class="card animate">
        <div class="card-header">
            <h3 class="card-title">
                <span v-if="editMode">
                    <i class="fas fa-pen-alt"></i>
                    Modifier un compte <span v-if="account_type" v-html="account_type"></span>
                </span>
                <span v-else>
                    <i class="fas fa-user-plus"></i>
                    Ajouter un compte <span v-if="account_type" v-html="account_type"></span>
                </span>
            </h3>
        </div>
        <form @submit.prevent="submit">
            <div class="card-body">
                <div class="form-group" v-show="$page.props.roles.length > 1" >
                    <strong>{{ __('ui.administrator.Role') }} <sup class="text-danger">*</sup></strong>
                    <div class="alert alert-warning" v-if="editMode && $page.props.auth.user.id === itemToEdit.id">
                        <i class="fas fa-exclamation-triangle mr-1"></i>
                        <strong>{{ __('ui.administrator.Warning') }}</strong>,
                        {{ __('ui.administrator.YouEditYourOwnRoles') }}
                    </div>

                    <div class="form-check pl-0" :class="{'is-invalid': form.errors.role}">
                        <template v-for="(role,index) in $page.props.roles" :key="`role_${index}`">
                            <div class="icheck-primary">
                                <input type="radio" required :disabled="maxRoleCurrentUser < role.weight"
                                       :id="`role_${role.slug}`" v-model="form.role" :value="role.id">
                                <label class="form-check-label" :for="`role_${role.slug}`">
                                    {{ __(role.name) }}
                                </label>
                            </div>
                        </template>
                    </div>
                    <div class="invalid-feedback" v-if="form.errors.role">
                        {{ form.errors.role }}
                    </div>
                </div>
                <div class="form-group">
                    <label for="firstname">{{ __('ui.administrator.Firstname') }} <sup
                        class="text-danger">*</sup></label>
                    <div class="input-group" :class="{'is-invalid': form.errors.firstname}">
                        <BoInput id="firstname" :placeholder="__('ui.administrator.Firstname')" type="text"
                                 v-model="form.firstname"
                                 :class="{'is-invalid': form.errors.firstname}" autocomplete="firstname" autofocus
                                 required/>
                        <InputAppend icon="fa-user"/>
                    </div>
                    <div class="invalid-feedback" v-if="form.errors.firstname">
                        {{ form.errors.firstname }}
                    </div>
                </div>
                <div class="form-group">
                    <label for="lastname">{{ __('ui.administrator.Name') }} <sup class="text-danger">*</sup></label>
                    <div class="input-group" :class="{'is-invalid': form.errors.lastname}">
                        <BoInput type="text" autocomplete="lastname" class="form-control"
                                 :class="{'is-invalid': form.errors.lastname}" id="lastname" v-model="form.lastname"
                                 :placeholder="__('ui.administrator.Name')" required/>
                        <InputAppend icon="fa-user"/>
                    </div>
                    <div class="invalid-feedback" v-if="form.errors.lastname">
                        {{ form.errors.lastname }}
                    </div>
                </div>
                <div class="form-group">
                    <label for="email">{{ __('ui.administrator.EmailAddress') }} <sup
                        class="text-danger">*</sup></label>
                    <div class="input-group" :class="{'is-invalid': form.errors.email}">
                        <BoInput type="email" autocomplete="username" class="form-control"
                                 :class="{'is-invalid': form.errors.email}" id="email" v-model="form.email"
                                 :placeholder="__('ui.administrator.Email')" required/>
                        <InputAppend icon="fa-envelope"/>
                    </div>
                    <div class="invalid-feedback" v-if="form.errors.email">
                        {{ form.errors.email }}
                    </div>
                </div>
                <div class="form-group">
                    <div v-if="editMode">
                        <label for="new_password">{{ __('ui.administrator.NewPassword') }}</label>
                        <div v-if="showNewPasswordBox" class="mb-2">
                            <div class="input-group" :class="{'is-invalid': form.errors.password}">
                                <input type="text" class="form-control" :class="{'is-invalid': form.errors.password}"
                                       id="new_password" v-model="form.password" :placeholder="__('ui.login.Password')">
                                <InputAppend icon="fa-lock"/>
                            </div>
                            <div class="invalid-feedback" v-if="form.errors.password">
                                {{ form.errors.password }}
                            </div>
                        </div>
                        <button @click="cancelNewPasswordBox" v-if="editMode" type="button"
                                class="btn btn-default btn-block btn-sm">
                            <span v-if="!showNewPasswordBox"><i
                                class="fa fa-lock"></i> {{ __('ui.administrator.SetNewPassword') }}</span>
                            <span v-else>{{ __('ui.administrator.Cancel') }}</span>
                        </button>
                    </div>
                    <div v-else>
                        <label for="password">{{ __('ui.login.Password') }} <sup class="text-danger">*</sup></label>
                        <div class="input-group" :class="{'is-invalid': form.errors.password}">
                            <BoInput type="text" class="form-control" :class="{'is-invalid': form.errors.password}"
                                     id="password" v-model="form.password" :placeholder="__('ui.login.Password')"
                                     required/>
                            <InputAppend icon="fa-lock"/>
                        </div>
                        <div class="invalid-feedback" v-if="form.errors.password">
                            {{ form.errors.password }}
                        </div>
                    </div>
                </div>
                <!-- Dynamics user metas  -->
                <template v-for="(user_meta,index) in additional_user_metas" :key="`user_meta{$index}`">
                    <div class="form-group">
                        <label :for="`user_meta_${user_meta.key}`">{{ user_meta.label }}
                            <sup v-if="user_meta.required" class="text-danger">*</sup>
                        </label>
                        <template v-if="user_meta.type === 'string'">
                            <div class="input-group" :class="{'is-invalid': form.errors[`meta_${user_meta.key}`]}">
                                <BoInput type="text" class="form-control"
                                         :class="{'is-invalid': form.errors[`meta_${user_meta.key}`]}"
                                         :id="`user_meta_${user_meta.key}`" v-model="form[`meta_${user_meta.key}`]"
                                         :placeholder="user_meta.label" :required="user_meta.required"/>
                                <InputAppend v-if="user_meta.icon !== false" :icon="user_meta.icon"/>
                            </div>
                            <div class="invalid-feedback" v-if="form.errors[`meta_${user_meta.key}`]">
                                {{ form.errors[`meta_${user_meta.key}`] }}
                            </div>
                        </template>
                        <template v-if="user_meta.type === 'date-range'">
                            <BoDowinoDateRangePicker class="date-picker-add-edit" position="center" :minDate="null"
                                                     :maxDate="null" v-model="form[`meta_${user_meta.key}`]"/>
                            <div class="invalid-feedback" v-if="form.errors[`meta_${user_meta.key}`]">
                                {{ form.errors[`meta_${user_meta.key}`] }}
                            </div>
                        </template>
                        <template v-if="user_meta.type === 'combobox'">
                            <select class="form-control" :class="{'is-invalid': form.errors[`meta_${user_meta.key}`]}"
                                    v-model="form[`meta_${user_meta.key}`]" :required="user_meta.required">
                                <option v-for="option in dynamic_options[user_meta.options_ref]"
                                        :disabled="option.value === null" :value="option.value" :key="option.value">
                                    {{ option.label }}
                                </option>
                            </select>
                            <div class="invalid-feedback" v-if="form.errors[`meta_${user_meta.key}`]">
                                {{ form.errors[`meta_${user_meta.key}`] }}
                            </div>
                        </template>
                        <template v-if="user_meta.type === 'numeric_stepper'">
                            <BoInput type="number" class="form-control"
                                     :min="user_meta.min"
                                     :max="user_meta.max"
                                     :class="{'is-invalid': form.errors[`meta_${user_meta.key}`]}"
                                     :id="`user_meta_${user_meta.key}`" v-model="form[`meta_${user_meta.key}`]"
                                     :placeholder="user_meta.label" :required="user_meta.required"/>
                            <div class="invalid-feedback" v-if="form.errors[`meta_${user_meta.key}`]">
                                {{ form.errors[`meta_${user_meta.key}`] }}
                            </div>
                        </template>
                    </div>
                </template>
                <!-- End of Dynamics user metas  -->
            </div>
            <div class="card-footer">
                <div class="row">
                    <div class="col-sm-6" v-if="editMode">
                        <button type="button" @click="$emit('cancelEditing')" class="btn btn-block btn-default"><i
                            class="fas fa-undo"></i> {{ __('ui.administrator.Cancel') }}
                        </button>
                    </div>
                    <div :class="{'col-sm-12': !editMode, 'col-sm-6': editMode}">
                        <button type="submit" :disabled="form.processing" class="btn btn-block btn-primary"><i
                            class="fas fa-save"></i> {{ __('ui.administrator.Register') }}
                        </button>
                    </div>
                </div>
            </div>
        </form>
    </div>
</template>
