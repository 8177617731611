<script setup>
import {computed, ref, watch} from "vue";
import {usePage} from "@inertiajs/inertia-vue3";
import {throttle} from "lodash/function";
import {pickBy} from "lodash";
import {Inertia} from "@inertiajs/inertia";
import Pagination from "@/Components/Pagination.vue";
import Modal from "@/Components/Modal.vue";
import {isJson, syntaxHighlightJson} from "@/Helpers/json";
import BoDowinoDateRangePicker from"@/Components/DateRangePicker.vue";
import moment from 'moment';

/**
 * Filter received from the controller
 * @type {ComputedRef<unknown>}
 */
const filters = computed(() => usePage().props.value.filters );
/**
 * Params send to the controller
 * @type {Ref<UnwrapRef<{search, field: *, direction}>>}
 */
const params = ref({
    log_type:filters.value.log_type,
    field:filters.value.field,
    direction:filters.value.direction,
    start_date: filters.value.start_date,
    end_date: filters.value.end_date,
});

/**
 * DatePicker model
 * @type {Ref<UnwrapRef<*[]>>}
 */
const dates_filters = ref([filters.value.start_date, filters.value.end_date])

/**
 * Watcher for the datePicker model :
 * Update the params values
 */
watch(dates_filters, async (newDate, oldDate) => {
    if(newDate){
        params.value.start_date = newDate[0];
        params.value.end_date = newDate[1];
    }else{
        params.value.start_date = null;
        params.value.end_date = null;
    }
})

/**
 * Watcher for the params :
 * Refresh data
 */
watch(params, throttle(function (){
    refresh_data()
}, 300), {deep:true})

const refresh_data = () => {
    //clean empty params
    let my_params = pickBy(params.value);
    //request
    Inertia.get(route('admin.app-logs.index'), my_params, {replace:true, preserveState:true})
}

/**
 * Sort handler on columns header
 * @param field
 */
const sort = (field) => {
    params.value.field = field
    params.value.direction = params.value.direction === 'asc' ? 'desc' : 'asc';
}

const filters_by_log_type = (e) => {
    if(e.currentTarget.value === 'false'){
        params.value.log_type = false;
    }else{
        params.value.log_type = e.currentTarget.value;
    }
}
const show_structured_data = (log) => {
    logToDetail.value = log;
    logDataToDetail.value = syntaxHighlightJson(JSON.stringify(JSON.parse(log.data), null, 4));
    logDataToDetailTitle.value = `${log_types.value[log.log_type]}  <small><span class="badge badge-info">${formatDate(log.created_at)}</span></small>`;
}
const logToDetail = ref(null);
const logDataToDetail = ref('');
const logDataToDetailTitle = ref('');
const showDetailLogModal = computed( () => {
    return logDataToDetail.value !== '' &&  logToDetail.value !== null;
} )
const closeDetailLogModal = () => {
    logToDetail.value = null;
};
/**
 * Data for the datatable - List of logs
 * @type {ComputedRef<*>}
 */
const items = computed(() => usePage().props.value.logs );
const log_types = computed(() => usePage().props.value.log_types );
const log_types_used = computed(() => usePage().props.value.log_types_used);
const count_log_types = computed(() => {return Object.keys(log_types.value).length });

const formatDate = ($date) => moment($date).format('[le] DD MMMM YYYY [à] HH:mm:ss')




</script>

<template>
    <Modal id="detail_modal" extra-class="modal-lg" :show="showDetailLogModal" @close="closeDetailLogModal">
        <template #title>
            <i class="fas fa-info-circle"></i> <span v-html="logDataToDetailTitle"></span>
        </template>
        <template #content>
            <div class="modal-body">
                <pre class="json-value" v-html="logDataToDetail"></pre>
            </div>
        </template>
    </Modal>

    <div class="card animate">
        <div class="card-header">
            <h3 class="card-title"><i class="fas fa-list"></i> {{ __('ui.frontLogs.ApplicationLogsList') }}</h3>
            <div class="card-tools">
                <ul class="nav nav-pills ml-auto">
                    <li class="nav-item">
                        <button @click="refresh_data" class="btn btn-sm btn-primary"><i class="fas fa-sync"></i></button>
                    </li>
                </ul>
            </div>
        </div>
        <div class="card-body">
            <div id="administrators_wrapper" class="dataTables_wrapper dt-bootstrap4">
                <div class="row">
                    <div class="col-sm-12">
                        <div id="administrators_filter" class="dataTables_filter">
                            <div class="form-inline justify-content-end" >
                                <BoDowinoDateRangePicker v-model="dates_filters"/>
                                <template v-if="count_log_types > 1">
                                    <label for="log_type_filter" class="mr-1">{{ __('ui.frontLogs.LogsType') }}</label>
                                    <select @change="filters_by_log_type" id="log_type_filter" class="form-control">
                                        <option :selected="filters.log_type === 'false'" value="false">{{ __('ui.frontLogs.All') }}</option>
                                        <option :selected="filters.log_type === log_type_key"
                                                v-for="(log_type_key, index) in log_types_used" :key="log_type_key" :value="log_type_key">
                                            {{ log_types[log_type_key] }}
                                        </option>
                                    </select>
                                </template>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-12">
                        <table id="logs"
                               class="table table-bordered table-hover dataTable dtr-inline table-sm"
                               :class="{'table-striped' : items.total>0}"
                               role="grid" aria-describedby="logs_info">
                            <thead>
                            <tr role="row">
                                <th class="col-2" tabindex="0" aria-controls="logs"
                                    rowspan="1"
                                    colspan="1">
                                    {{ __('ui.frontLogs.LogType') }}
                                </th>
                                <th class="col-2" tabindex="0" aria-controls="logs"
                                    rowspan="1"
                                    colspan="1">
                                    {{ __('ui.frontLogs.Data') }}
                                </th>
                                <th class="col-2 sorting" :class="{'sorting_asc': params.field === 'created_at' && params.direction === 'asc','sorting_desc': params.field === 'created_at' && params.direction === 'desc' }" tabindex="0"
                                    @click="sort('created_at')"
                                    aria-controls="logs"
                                    rowspan="1" colspan="1" aria-sort="ascending"
                                    aria-label="Date: cliquez pour trier par ordre décroissant">
                                    {{ __('ui.frontLogs.Date') }}
                                </th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-if="items.total === 0">
                                <td colspan="4">
                                    <div class="text-muted text-center mx-5 my-3" role="alert">
                                        <em>{{ __('ui.frontLogs.NoLogToDisplay') }}</em>
                                    </div>

                                </td>
                            </tr>
                            <tr v-for="(log, index) in items.data" :key="log.id">
                                <td class="dtr-control sorting_1" tabindex="0">
                                    <template v-if="log_types[log.log_type]">{{ log_types[log.log_type]}}</template>
                                    <em class="text-muted" v-else>n/a</em>
                                </td>
                                <td class="dtr-control sorting_1" tabindex="0">
                                    <em class="text-muted" v-if="log.data===null">{{ __('ui.frontLogs.NoData') }}</em>
                                    <button class="btn btn-default btn-sm" v-else-if="isJson(log.data)" @click="show_structured_data(log)">
                                        <i class="fas fa-stream"></i> {{ __('ui.frontLogs.StructuredData') }}
                                    </button>
                                    <span v-else>{{log.data}}</span>
                                </td>
                                <td class="dtr-control sorting_1" tabindex="0">{{formatDate(log.created_at)}}</td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-12 col-md-5">
                        <div class="dataTables_info mb-2 mb-sm-0" id="administrators_info" role="status"
                             aria-live="polite">{{ __('ui.administrator.DisplayOf') }} {{ items.from }} {{ __('ui.administrator.To') }} {{ items.to }} {{ __('ui.administrator.On') }}
                            {{ items.total }} {{ items.total > 1 ? __('ui.administrator.Entries') : __('ui.administrator.Entry') }}
                        </div>
                    </div>
                    <div class="col-sm-12 col-md-7">
                        <Pagination class="mt-2 mb-sm-0" id="administrators_paginate" :items="items"/>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
