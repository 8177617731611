<script setup>
import {Link} from '@inertiajs/inertia-vue3';
import {gsap} from "gsap";
import {onMounted, useSlots} from "vue";
import {ref} from "vue";

const props = defineProps({
    menuIcon: {
        type: String,
        default: 'fa-circle',
    },
    href: {
        type: String,
        default: "#",
    },
    active: {
        type: Boolean,
        default: false,
    },
});
const submenuOpen = ref(props.active);
const submenuIsViewing = ref(false);
const submenu = ref(null);
const toggleSubMenu = (e) => {
    submenuOpen.value = !submenuOpen.value;
    if (submenuOpen.value) {
        if (submenuIsViewing.value === false) {
            gsap.set(submenu.value, {height: 0, autoAlpha: 0});
        }
        submenuIsViewing.value = true;

        gsap.to(submenu.value, {
            duration: .3, height: 'auto', autoAlpha: 1,ease: "power2.out", onComplete: () => {
                submenuIsViewing.value = false;
            }
        });
    } else {
        submenuIsViewing.value = true;
        gsap.to(submenu.value, {
            duration: .3, height: 0, autoAlpha: 0,ease: "power2.out", onComplete: () => {
                submenuIsViewing.value = false;
            }
        });
    }
}


</script>

<template>
    <li class="nav-item" :class="{'submenu-is-opening':submenuIsViewing, 'menu-open': submenuOpen }">
        <a :href="props.href" class="nav-link" :class="{'active':props.active}" @click="toggleSubMenu" v-if="$slots.subMenu">
            <i class="nav-icon fas" :class="props.menuIcon"></i>
            <p>
                <slot/>
                <i class="right fas fa-angle-left" v-if="$slots.subMenu"></i>
            </p>
        </a>
        <Link :href="props.href" class="nav-link"  :class="{'active':props.active}" v-else>
            <i class="nav-icon fas" :class="props.menuIcon"></i>
            <p>
                <slot/>
                <i class="right fas fa-angle-left" v-if="$slots.subMenu"></i>
            </p>
        </Link>
        <ul ref="submenu" class="nav nav-treeview" v-if="$slots.subMenu">
            <slot name="subMenu"></slot>
        </ul>
    </li>
</template>

<style scoped>
.nav-treeview {
    overflow: hidden;
}
.nav-sidebar .submenu-is-opening > .nav-treeview{
    display: block;
}

</style>
