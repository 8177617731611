/**
 * Chargement asynchrone des données des départements depuis le fichier json "departements-region.json"
 * @returns {Promise<void>}
 */
const get_departements_list = async () => {
    try {
        const jsonData = await import('../datas/departements-region.json'),
            data = jsonData.default.map((el) => {
                return {label: `${el.num_dep} - ${el.dep_name}`, value: el.num_dep}
            });
        data.unshift({label: 'Sélectionnez un département', value: null})
        return Promise.resolve(data);
    } catch (error) {
        console.error('Erreur lors du chargement du fichier JSON :', error);
        return  Promise.resolve([]);
    }
}

export {get_departements_list}
