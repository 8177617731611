const isJson = (str) => {
    str = typeof str !== "string"
        ? JSON.stringify(str)
        : str;

    try {
        str = JSON.parse(str);
    } catch (e) {
        return false;
    }

    if (typeof str === "object" && str !== null) {
        return true;
    }

    return false;
}
const  syntaxHighlightJson = (json) => {
    json = json.replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;');
    return json.replace(/("(\\u[a-zA-Z0-9]{4}|\\[^u]|[^\\"])*"(\s*:)?|\b(true|false|null)\b|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?)/g, function (match) {
        var cls = 'number';
        if (/^"/.test(match)) {
            if (/:$/.test(match)) {
                cls = 'key';
            } else {
                cls = 'string';
            }
        } else if (/true|false/.test(match)) {
            cls = 'boolean';
        } else if (/null/.test(match)) {
            cls = 'null';
        }
        return '<span class="' + cls + '">' + match + '</span>';
    });
}

export  {isJson, syntaxHighlightJson};
