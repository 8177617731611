<script setup>
import {computed, onMounted, ref, watch} from "vue";
import {usePage} from "@inertiajs/inertia-vue3";
import Pagination from "@/Components/Pagination.vue";
import DiagTags from "@/Pages/Diagnostics/partials/DiagTags.vue";
import {hasRole} from "@/Helpers/useRoles.js";
import ICheckbox from "@/Components/ICheckbox.vue";
import {debounce} from "lodash/function";
import Modal from "@/Components/Modal.vue";
import {useSynchroLocalData} from "@/services/synchro_data"
import {Inertia} from "@inertiajs/inertia";
import {notify} from "@kyvg/vue3-notification";

const emit = defineEmits(['editItem', 'clickOnDiagnostic', 'ask_for_sync_to_local']);

// Diagnostics reçu par le controller
const diagnostics = computed(() => usePage().props.value.diagnostics);

// Nombre total de diagnotics sur la page du tableau
const nb_total_diagnostics_current_page = computed(() => diagnostics.value.data.length);

// Liste des ids de diagnotics sur la page du tableau
const diagIdOnPage = computed(() => diagnostics.value.data.map(diag => diag.id));

// Diagnostics sélectionnés actuellement sur la page en cours
const diagSelectedOnCurrentPage = computed(() => selected_diag.value.filter(diag => diagIdOnPage.value.includes(diag.id)));

const props = defineProps({
    currentItem: {
        type: Object,
        default: null
    },
    display_edit_button: {
        type: Boolean,
        default: false
    }
});

/**
 * Filter received from the controller
 * @type {ComputedRef<unknown>}
 */
const filters = computed(() => usePage().props.value.filters);
const msg_nb_selected = computed(() => {
    const count_selected = selected_diag.value.length;
    return (count_selected === 1 ? `Un diagnostic sélectioné` : `${count_selected} diagnostics sélectionnés`);

})
// Tableau contenant la liste des diagnostics cochés toute page confondue
const selected_diag = ref([]);

//const local_diagnostics = ref(null);


const editItem = (item) => emit("editItem", item);

/**
 * Handler sur le checkbox toggleAll
 */
const clickCheckAllHandler = () => {
    // Deletes selected diagnostics from the current page.
    let new_selected = selected_diag.value.filter(diag => {
        // console.log(diag.id, diagIdOnPage.value);
        return !diagIdOnPage.value.includes(diag.id)
    });
    // If none or more diagnostics are selected on the current page, all diagnostics are selected.
    if (diagSelectedOnCurrentPage.value.length !== nb_total_diagnostics_current_page.value) {
        new_selected = [...new_selected, ...diagnostics.value.data]
    }
    selected_diag.value = new_selected;
}

// Reférence pour la checkbox select all
const check_all = ref(false);
const checkUncheckAllToggle = () => check_all.value = diagSelectedOnCurrentPage.value.length === nb_total_diagnostics_current_page.value

watch(diagSelectedOnCurrentPage, () => checkUncheckAllToggle())
watch(selected_diag, (diags) => emit("clickOnDiagnostic", diags))

// Suppression d'un diagnostics
const itemToDelete = ref(null);
const diagnotic_to_delete_name = ref('');
const formDeleteProcessing = ref(false);
const openSupressModal = (item) => {
    editItem(null);
    itemToDelete.value = item;
    diagnotic_to_delete_name.value = item.name;
};
const closeSupressModal = () => itemToDelete.value = null;
const showSuppressModal = computed(() => itemToDelete.value !== null)
const submitDelete = () => {
    const {deleteDiagnostic} = useSynchroLocalData();
    deleteDiagnostic(itemToDelete.value.id)
        .then(() => {
            notify({
                title: "Diagnostic supprimé",
                text: "Le diagnostic a bien été supprimé",
                type: "success",
            })
        })
        .catch((error) => {
            console.error(error);
        })
        .finally(() => {
            itemToDelete.value = null;
            Inertia.visit(route('admin.diagnotics.index'),
                {
                    only: ['diagnostics'],
                    replace: true,
                    preserveState: true,
                })
        })
}


// Gestion et sauvegarde de la liste des diagnostics à synchroniser manuellement pour les admins
const admin_diag_for_sync = ref(usePage().props.value.diagnostics_for_sync);
const updateAdminDiagSyncRequest = debounce(() => {
    axios.post(route('admin.diagnotics.update_sync_list'), {sync_list: admin_diag_for_sync.value})
        .then((res) => {
            emit("ask_for_sync_to_local")
        })
}, 500);
watch(admin_diag_for_sync, () => updateAdminDiagSyncRequest())


/**
 * Params send to the controller
 * @type {Ref<UnwrapRef<{search, field: *, direction}>>}
 */
const params = ref({
    search: filters.value.search
});
</script>

<template>
    <Modal id="suppress_modal" :show="showSuppressModal" @close="closeSupressModal">
        <template #title>
            <i class="fas fa-trash-alt"></i> Suppression d'un diagnostic
        </template>
        <template #content>
            <form @submit.prevent="submitDelete">
                <div class="modal-body">
                    Voulez-vous vraiment supprimer le diagnotic "<strong class="text-info">{{
                        diagnotic_to_delete_name
                    }}</strong>" ?
                </div>
                <div class="modal-footer">
                    <button type="button" @click="closeSupressModal" class="btn btn-secondary">Annuler</button>
                    <button type="submit" :disabled="formDeleteProcessing" class="btn btn-danger">Supprimer</button>
                </div>
            </form>
        </template>
    </Modal>

    <div class="card animate">
        <div class="card-header">
            <h3 class="card-title"><i class="fas fa-list"></i> Liste des diagnostics</h3>
            <span v-if="selected_diag.length" class="float-right badge badge-info">{{ msg_nb_selected }}</span>
        </div>
        <div class="card-body">
            <div id="diagnostics_wrapper" class="dataTables_wrapper dt-bootstrap4" v-if="diagnostics.data.length">
                <div class="row">
                    <div class="col-sm-12">
                        <div id="diagnostics_filter" class="dataTables_filter">
                            <label for="search_diagnotics_input">Rechercher dans la liste :</label>
                            <input type="search" id="search_diagnotics_input" class="form-control form-control-sm"
                                   v-model="params.search" placeholder=""
                                   aria-controls="diagnostics">
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-12">
                        <div class="table-responsive">
                            <table id="diagnostics"
                                   class="table table-bordered table-hover table-striped dataTable dtr-inline table-sm"
                                   role="grid" aria-describedby="diagnostics_info">
                                <thead>
                                <tr role="row">
                                    <th tabindex="0"
                                        aria-controls="diagnostics"
                                        class="checkbox_col text-center">
                                        <input type="checkbox" @change="clickCheckAllHandler" v-model="check_all"/>
                                    </th>
                                    <th tabindex="0"
                                        aria-controls="diagnostics">
                                        Nom diagnotic
                                    </th>
                                    <th tabindex="0"
                                        aria-controls="diagnostics">
                                        Nom exploitant
                                    </th>
                                    <th tabindex="0"
                                        aria-controls="diagnostics">
                                        Informations
                                    </th>
                                    <th tabindex="0"
                                        aria-controls="diagnostics">
                                        Tags
                                    </th>
                                    <th v-if="hasRole('admin') || hasRole('super-admin')"
                                        aria-controls="diagnostics">
                                        Sync. local
                                    </th>
                                    <th tabindex="0"
                                        aria-controls="actions">
                                        Actions
                                    </th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr :class="{'table-primary' : props.currentItem === diagnostic}"
                                    v-for="(diagnostic, index) in diagnostics.data" :key="diagnostic.id">
                                    <td tabindex="0" class="checkbox_col text-center">
                                        <input type="checkbox" :value="diagnostic" v-model="selected_diag"/>
                                    </td>
                                    <td tabindex="0">{{ diagnostic.name }}</td>
                                    <td tabindex="0">{{ diagnostic.exploitant.fullname }}</td>
                                    <td tabindex="0">info</td>
                                    <td tabindex="0">
                                        <DiagTags :diagnostic="diagnostic"/>
                                    </td>
                                    <td tabindex="0" class="text-center"
                                        v-if="hasRole('admin') || hasRole('super-admin')">
                                        <ICheckbox :id="`sync-${diagnostic.id}`" v-model="admin_diag_for_sync"
                                                   :value="diagnostic.id"/>
                                    </td>
                                    <td tabindex="0" class="text-center">
                                        <button v-if="props.display_edit_button" class="btn btn-default btn-sm"
                                                type="button" @click="editItem(diagnostic)"><i class="fas fa-pen"></i>
                                        </button>
                                        <button class="btn btn-default btn-sm" type="button"
                                                @click="openSupressModal(diagnostic)"><i class="fas fa-trash-alt"></i>
                                        </button>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-12 col-md-5">
                        <div class="dataTables_info mb-2 mb-sm-0" id="diagnostics_info" role="status"
                             aria-live="polite">{{ __('ui.administrator.DisplayOf') }} {{ diagnostics.from }}
                            {{ __('ui.administrator.To') }} {{ diagnostics.to }} {{ __('ui.administrator.On') }}
                            {{ diagnostics.total }}
                            {{ diagnostics.total > 1 ? __('ui.administrator.Entries') : __('ui.administrator.Entry') }}
                        </div>
                    </div>
                    <div class="col-sm-12 col-md-7">
                        <Pagination class="mt-2 mb-sm-0" id="diagnostics_paginate" :items="diagnostics"/>
                    </div>
                </div>
            </div>
            <div class="jumbotron" v-else>
                <p class="text-center mb-0 text-uppercase">Actuellement aucun diagnostic</p>
            </div>
        </div>
    </div>
</template>

<style scoped>

</style>
