<script>
import InertiaLink from '@inertiajs/inertia-vue3';
import Icon from '@/Pages/Shared/Icon.vue'



export default {
    components: {
        Icon,
    },
    computed: {
        selectable_locale() {
            if(this.$page.props.locale == 'fr') {
                return 'en';
            }
            return 'fr'
        }
    },
}
</script>

<template>
    <div class="ml-4">
        <inertia-link :href="route('language', [selectable_locale])">
            <div v-if="selectable_locale == 'fr'">
                <label>fr</label>
            </div>
            <div v-else>
                <label>en</label>
            </div>
            <!--<icon class="w-5 h-5" :name="selectable_locale" />-->
        </inertia-link>
    </div>
</template>
