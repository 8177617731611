<script setup>

import {computed,  ref, watch} from "vue";
import {hasPermission} from "@/Helpers/useRoles.js";
import {Link, usePage} from '@inertiajs/inertia-vue3';
import {useSynchroLocalData} from "@/services/synchro_data"
import {notify} from "@kyvg/vue3-notification";

const props = defineProps({
    // Liste des diag sélectionnés dans la liste
    selected_diagnostics: {
        type: Array,
        default: []
    },
});



/**
 * Lien dynamique pour la modification d'un diagnostic
 * @type {ComputedRef<null|*>}
 */
const edit_diag_link = computed(() => {
    if(props.selected_diagnostics.length !== 1){
        return null;
    }
    const diag = props.selected_diagnostics[0];

    return route('front.diagnostics.edit', diag);
});
const edit_diagnotics_btn_disabled = computed(() => !(props.selected_diagnostics.length === 1));
const display_synthese_btn_disabled = computed(() => !(props.selected_diagnostics.length === 1));
const generate_bilan_btn_disabled = computed(() => !(props.selected_diagnostics.length >= 1));
const generate_bilan_label = computed(() => props.selected_diagnostics.length > 1 ? "Générer les bilans" : "Générer le bilan");
const collective_valorisation_btn_disabled = computed(() => !(props.selected_diagnostics.length > 1));
const create_referentiel_btn_disabled = computed(() => !(props.selected_diagnostics.length > 1));
const export_raw_data_btn_disabled = computed(() => props.selected_diagnostics.length < 1 );
const sync_btn_disabled = ref(false);

const launch_sync = (notify_at_end = false) => {
    sync_btn_disabled.value = true;
    const {synchronizeDiagnosticsOnDemand} = useSynchroLocalData();
    synchronizeDiagnosticsOnDemand()
        .then((res) => {
            if(notify_at_end){
                notify({
                    title: res.nb_added > 1 ? "Diagnostics synchronisés" : "Diagnostic synchronisé",
                    text: res.nb_added > 1 ? "Les diagnostics ont été synchronisés localement." : "Les diagnostic a été synchronisé localement.",
                    type: "success",
                })
            }
        })
        .finally(()=> sync_btn_disabled.value = false)
    //IdeleLocalIndexedDB.init(IdeleLocalIndexedDB.synchroDiagnotics, true, true);

}
// definition des fonctions et/ou données à exposer pour être accessible depuis les composants parents
defineExpose({
    launch_sync,
});
</script>

<template>
    <div class="mb-2 animate d-flex d-flex justify-content-start flex-wrap">
        <Link :href="edit_diag_link" class="btn btn-default btn-sm mr-2 mb-2" :class="{'disabled':edit_diagnotics_btn_disabled}" preserve-state>
            <i class="fa fa-pen"></i>
            Modifier le diagnostic
        </Link>
        <button :disabled="display_synthese_btn_disabled" class="btn btn-default btn-sm mr-2 mb-2"><i
            class="fa fa-chart-line"></i> Afficher la synthèse
        </button>
        <button :disabled="generate_bilan_btn_disabled" class="btn btn-default btn-sm mr-2 mb-2"><i
            class="fa fa-file-download"></i> {{ generate_bilan_label }}
        </button>
        <button :disabled="collective_valorisation_btn_disabled" class="btn btn-default btn-sm mr-2 mb-2"><i
            class="fa fa-user-friends"></i> Valorisation collective
        </button>
        <button v-if="hasPermission('manage-referenciels')" :disabled="create_referentiel_btn_disabled" class="btn btn-default btn-sm mr-2 mb-2"><i
            class="fa fa-plus"></i> Créer un référentiel
        </button>
        <button :disabled="export_raw_data_btn_disabled" class="btn btn-default btn-sm mr-2 mb-2"><i
            class="fa fa-file-download"></i> Export des données brutes
        </button>
        <button :disabled="sync_btn_disabled" class="btn btn-default btn-sm mr-2 mb-2" @click="launch_sync(true)">
            <i class="fa fa-sync"></i>
            Synchronisation locale
            <span class="badge badge-info"></span>
        </button>
    </div>
</template>
