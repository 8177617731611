import {IdeleLocalIndexedDB} from "./ideleIndexedDb.js";
import axios from "axios";

const synchronizeLocalToServer = async (db) => {
    console.log("      Début de synchronisation des données Local => Serveur...");
    // On regarde dans un premier temps si des données sont à synchroniser dans le store d'update
    const result = await new Promise((resolve, reject) => {
        // Ouvrir une transaction en mode lecture seule sur le store d'update
        const transaction = IdeleLocalIndexedDB.makeTX(db, IdeleLocalIndexedDB.pendingActionsStoreName, 'readonly');

        // Accéder au magasin d'objets
        const store = transaction.objectStore(IdeleLocalIndexedDB.pendingActionsStoreName);

        // Demander tous les éléments dans le magasin d'objets
        const getAllRequest = store.getAll();
        const keysRequest = store.getAllKeys();

        let values, keys;

        getAllRequest.onsuccess = function(event) {
            values = event.target.result
            if (keys !== undefined) {
                resolve(values.map((value, index) => ({ key: keys[index], value })));
            }
        };
        keysRequest.onsuccess= (event) => {
            keys = event.target.result;
            if (values !== undefined) {
                resolve(values.map((value, index) => ({ key: keys[index], value })));
            }
        }
        // Gérer les erreurs lors de la récupération des éléments
        transaction.onerror = function(event) {
            console.error("Erreur lors de la récupération des éléments :", event.target.error);
            reject(event.target.error);
        };
    })
    if(result.length){
        await new Promise((resolve, reject) => {
            function traiter(index){
                if (index < result.length) {
                    axios.post(route('admin.synchro'), result[index].value)
                        .then(res => {
                            // supprimer la ligne du store update
                            const transaction = IdeleLocalIndexedDB.makeTX(db, IdeleLocalIndexedDB.pendingActionsStoreName, 'readwrite');
                            const store = transaction.objectStore(IdeleLocalIndexedDB.pendingActionsStoreName);
                            store.delete(result[index].key);
                        })
                        .catch(err => console.warn(err))
                        .finally(() => traiter(index + 1))

                }else{
                    resolve();
                }
            }
            traiter(0);
        })
    }else{
        console.log("         Aucune donnée à sunchroniser vers le serveur.");
    }

    console.log("      Fin de synchronisation des données Local => Serveur.");
}

/**
 * Ajoute la tâche diag au store updateObjectStore
 * @param db
 * @param action (delete, create or update)
 * @param model
 * @param id
 * @param value
 * @returns {Promise<unknown>}
 */
const addDataToUpdateQueue = async(db, action,model, id=null, value=null) => {
    return new Promise((resolve, reject) => {
        // Commencer une transaction en mode de lecture/écriture
        const transaction = IdeleLocalIndexedDB.makeTX(db, IdeleLocalIndexedDB.pendingActionsStoreName, 'readwrite');

        // Accéder à l'object store
        const store = transaction.objectStore(IdeleLocalIndexedDB.pendingActionsStoreName);

        // Créer un nouvel objet à ajouter
        const nouvelObjet = { action, model, id, object:value };

        // Ajouter l'entrée à l'object store
        const requestAdd = store.add(nouvelObjet);

        requestAdd.onsuccess = (event) => {
            const id = event.target.result;
            console.log(`      Ajout de la tâche de suppression au store ${IdeleLocalIndexedDB.pendingActionsStoreName}. Id = ${id}`);
            resolve(id);
        };
    });
}

const removeDataToUpdateQueue = async (db, id) => {
    return new Promise((resolve, reject) => {
        const transaction = IdeleLocalIndexedDB.makeTX(db, IdeleLocalIndexedDB.pendingActionsStoreName, 'readwrite');
        const store = transaction.objectStore(IdeleLocalIndexedDB.pendingActionsStoreName);
        const requestDelete = store.delete(id);
        requestDelete.onsuccess = () => {
            console.log(`      Suppression de la tâche d'id ${id}`);
            resolve()
        };
        requestDelete.onerror = (event) => {
            console.error(event.target.error);
            reject(event.target.error); // Rejeter la promesse en cas d'erreur
        };
    })
}

export {synchronizeLocalToServer, addDataToUpdateQueue, removeDataToUpdateQueue}
