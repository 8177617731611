<script setup>
import {onMounted, onUnmounted, ref} from "vue";

const isFullScreenMode = ref(false);
const toggleFullscreenMode = () => {
    if (document.fullscreenElement) {
        document.exitFullscreen();
        isFullScreenMode.value = false;
    } else {
        document.documentElement.requestFullscreen();
        isFullScreenMode.value = true;
    }
}
const fullscreenchanged = (e) => {
    if ( document.fullscreenElement ) {
        isFullScreenMode.value = true;
    }else{
        isFullScreenMode.value = false;
    }
}
onMounted(()=> {
    document.addEventListener('fullscreenchange', fullscreenchanged)
})
onUnmounted( () => {
    document.removeEventListener('fullscreenchange', fullscreenchanged)
})
</script>

<template>
    <button @click="toggleFullscreenMode"  type="button" class="btn btn-link nav-link" href="#" role="button">
        <i class="fas" :class="{'fa-expand-arrows-alt' : !isFullScreenMode, 'fa-compress-arrows-alt' : isFullScreenMode}"></i>
    </button>
</template>

