<template>
    <Head title="Gestion des ateliers personnalisés"/>
    <BoAuthenticatedLayout>
        <template #page_title>Gestion des ateliers personnalisés</template>
        <transition @before-enter="onBeforeEnterOpacity"
                    @enter="onEnterOpacity"
                    appear mode="out-in">
            <div class="container-fluid">

                <div class="row">
                    <div class="col-sm-8">
                       <List/>
                    </div>
                    <div class="col-sm-4">
                        <AddToolbox :standard-atelier="standard_ateliers"/>
                    </div>
                </div>
            </div>
        </transition>
    </BoAuthenticatedLayout>
</template>

<script setup>
import {Head, usePage} from "@inertiajs/inertia-vue3";
import BoAuthenticatedLayout from '@/Layouts/AuthenticatedLayout.vue';
import {onBeforeEnterOpacity, onEnterOpacity} from "@/Helpers/animations.js";
import AddToolbox from "@/Pages/CustomAteliers/partials/AddToolbox.vue";
import {flattenAteliers} from "@/Pages/CustomAteliers/helpers/atelier_helper.js";
import List from "@/Pages/CustomAteliers/partials/List.vue";

const standard_ateliers = flattenAteliers(usePage().props.value.standard_ateliers);
// const user = usePage().props.value.auth.user;
// const standard_ateliers = usePage().props.value.standard_ateliers;

// console.log(standard_ateliers);

</script>
