<script setup>

import { Link } from '@inertiajs/inertia-vue3';
import BoMainNavMenuItem from "@/Components/MenuItem.vue";
import {ref} from "vue";
import {hasPermission, hasRole} from "@/Helpers/useRoles"
import LanguageSelector from "@/Pages/Shared/LanguageSelector.vue";

const menu = ref(null)
defineExpose({
    menu
})

</script>
<template>
    <aside ref="menu" class="main-sidebar sidebar-dark-primary elevation-4">
        <Link :href="route('admin.dashboard')" class="brand-link">
            <span class="brand-text font-weight-light">Admin - <b>{{ $page.props.app.name }}</b></span>
        </Link>
        <div class="sidebar">
            <nav class="mt-2 text-sm">
                <ul class="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
                    <BoMainNavMenuItem  :active="$page.component.startsWith('Dashboard')" menu-icon="fa-home" :href="route('admin.dashboard')">{{ __('ui.mainNavigation.Home') }}</BoMainNavMenuItem>
                    <BoMainNavMenuItem v-if="hasPermission('manage-admins')" :active="$page.component.startsWith('Administrators')" menu-icon="fa-users" :href="route('admin.administrators.index')">Gestion des comptes</BoMainNavMenuItem>
                    <BoMainNavMenuItem :href="route('admin.diagnotics.index')" :active="$page.component.startsWith('Diagnostics')" menu-icon="fa-file-alt">Gestion des diagnostics</BoMainNavMenuItem>
                    <BoMainNavMenuItem :href="route('admin.custom-ateliers.index')" v-if="hasPermission('create-custom-atelier')" :active="$page.component.startsWith('CustomAteliers')" menu-icon="fa-solid fa-tractor">Ateliers personnalisés</BoMainNavMenuItem>
                    <BoMainNavMenuItem v-if="hasPermission('manage-referenciels')" :active="$page.component.startsWith('Referentiels')" :href="route('admin.referentiels.index')" menu-icon="fa-file-alt">Gestion des référentiels</BoMainNavMenuItem>
                    <BoMainNavMenuItem v-if="hasPermission('view-app-logs')" :active="$page.component === 'FrontLogs/Index'" menu-icon="fa-list" :href="route('admin.app-logs.index')">{{ __('ui.mainNavigation.ApplicationLogs') }}</BoMainNavMenuItem>
                    <BoMainNavMenuItem v-if="hasPermission('view-back-logs')" :active="$page.component === 'BackLogs/Index'" menu-icon="fa-list" :href="route('admin.back-logs.index')">{{ __('ui.mainNavigation.BackofficeLogs') }}</BoMainNavMenuItem>

                    <template v-if="hasPermission('manage-roles-permissions')">
                        <li class="nav-header text-uppercase">{{ __('ui.mainNavigation.RolesAndPermissions') }}</li>
                        <BoMainNavMenuItem :active="$page.component === 'Roles/Index'" menu-icon="fa-user-tag text-warning" :href="route('admin.roles.index')">{{ __('ui.mainNavigation.RoleManagement') }}</BoMainNavMenuItem>
                        <BoMainNavMenuItem :active="$page.component === 'Permissions/Index'" menu-icon="fa-clipboard-list text-warning" :href="route('admin.permissions.index')">{{ __('ui.mainNavigation.PermissionManagement') }}</BoMainNavMenuItem>
                    </template>
                    <template v-if="hasPermission('manage-roles-permissions')">
                        <li class="nav-header text-uppercase">{{ __('ui.mainNavigation.Log') }}</li>
                        <BoMainNavMenuItem :active="$page.component === 'FrontLogs/Generator'" menu-icon="fa-list text-warning" :href="route('admin.app-logs.generator')">{{ __('ui.mainNavigation.LogGenerator') }}</BoMainNavMenuItem>
                    </template>
                    <LanguageSelector/>
                </ul>
            </nav>

        </div>

    </aside>
</template>
