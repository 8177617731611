<script setup>
import BoAuthenticatedLayout from '@/Layouts/AuthenticatedLayout.vue';
import {Head, usePage} from '@inertiajs/inertia-vue3';
import {computed, onMounted, onUnmounted} from "vue";
import {gsap} from "gsap";

const account_disabled = computed(() => usePage().props.value.account_disabled || false );

const onEnter = (el, done) =>{
    gsap.to(el, {opacity:1, y:0,onComplete:done, duration:.8, ease:"power2.out"})
}

const onBeforeEnter = (el) =>{
    gsap.set(el, {opacity:0, y:40})
}

onMounted(() => {
    document.body.classList.add("dashboard");
})


onUnmounted(() => {
    document.body.classList.remove("dashboard");
})

</script>

<template>
    <Head title="Dashboard" />

    <BoAuthenticatedLayout :has-breadcrumb="false">
        <div class="container-fluid">
            <div class="row">
                <div class="col-sm-12 text-center">
                    <transition @before-enter="onBeforeEnter"
                                @enter="onEnter"
                                appear
                                mode="out-in">
                        <div>
                            <h1>{{ __('ui.dashboard.Hello') }} <strong>{{ $page.props.app.name }}</strong></h1>
                            <div v-if="account_disabled" class="row">
                                <div class="col-4 offset-4">
                                    <div class="mt-5 alert alert-warning">
                                        <p class="lead mb-0">
                                        <i class="fas fa-exclamation-triangle"></i> Votre compte est désactivé.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </transition>
                </div>
            </div>
        </div>
    </BoAuthenticatedLayout>
</template>
