<script setup>
import {usePage} from "@inertiajs/inertia-vue3";
import {ref, computed} from "vue";
import Pagination from "@/Components/Pagination.vue";
import {watch} from "vue";
import {throttle} from "lodash/function";
import {pickBy} from "lodash";
import {Inertia} from "@inertiajs/inertia";
import Modal from "@/Components/Modal.vue";
import {notify} from "@kyvg/vue3-notification";

// props
const props = defineProps({
    currentItem: {
        type: Object,
        default:null
    },
});

/**
 * Filter received from the controller
 * @type {ComputedRef<unknown>}
 */
const filters = computed(() => usePage().props.value.filters );

/**
 * Params send to the controller
 * @type {Ref<UnwrapRef<{search, field: *, direction}>>}
 */
const params = ref({
    search:filters.value.search,
    field:filters.value.field,
    direction:filters.value.direction
});



// Events emited
const emit = defineEmits(['editItem']);

/**
 * Sort handler on columns header
 * @param field
 */
const sort = (field) => {
    editItem(null);
    params.value.field = field
    params.value.direction = params.value.direction === 'asc' ? 'desc' : 'asc';
}

/**
 * Watcher for params
 * Make an Inertia request with cleaned params
 */
watch(params, throttle(function (){
    //clean empty params
    let my_params = pickBy(params.value);

    //request
    Inertia.get(route('admin.permissions.index'), my_params, {replace:true, preserveState:true})
}, 300), {deep:true})

const editItem = (item) =>  emit("editItem", item);

const openSupressModal = (item) => {
    editItem(null);
    itemToDelete.value = item;
    permission_name.value = item.name
};
const closeSupressModal = () => {
    itemToDelete.value = null;
};
const showSuppressModal = computed( () => {
    return itemToDelete.value !== null ;
} )
const itemToDelete = ref(null);
const formDeleteProcessing = ref(false);
const permission_name = ref('');
const submitDelete = () => {
    formDeleteProcessing.value = true;
    Inertia.delete(route('admin.permissions.destroy', itemToDelete.value.id), {
        replace:true,
        preserveState:true,
        onSuccess: (page) => {
            itemToDelete.value = null;
            const success_msg = page.props.flash.success;
            if (success_msg) {
                notify({
                    title: usePage().props.value.language['ui.permission.Deleted'],
                    text: usePage().props.value.language['ui.permission.SuccessMsgDeleted'],
                    type: "success",
                })
            }
        },
        onError: (errors) => {console.log(errors);},
        onFinish: () => formDeleteProcessing.value = false
    })
}

/**
 * Data for the datatable - List of administrators
 * @type {ComputedRef<*>}
 */
const items = computed(() => usePage().props.value.permissions );
</script>
<template>

    <Modal id="suppress_modal" :show="showSuppressModal" @close="closeSupressModal">
        <template #title>
            <i class="fas fa-trash-alt"></i> {{ __('ui.permission.DeleteAPermission') }}
        </template>
        <template #content>
            <form @submit.prevent="submitDelete">
                <div class="modal-body">
                    {{ __('ui.permission.DeleteMessage') }} "<strong class="text-info">{{ __(permission_name) }}</strong>" ?
                </div>
                <div class="modal-footer">
                    <button type="button" @click="closeSupressModal" class="btn btn-secondary">{{ __('ui.administrator.Cancel') }}</button>
                    <button type="submit" :disabled="formDeleteProcessing" class="btn btn-danger">{{ __('ui.role.Delete') }}</button>
                </div>
            </form>
        </template>
    </Modal>


    <div class="card animate">
        <div class="card-header">
            <h3 class="card-title"><i class="fas fa-list"></i> {{ __('ui.role.ListExistingRoles') }}</h3>
        </div>
        <div class="card-body">
            <div id="roles_wrapper" class="dataTables_wrapper dt-bootstrap4">
                <div class="row">
                    <div class="col-sm-12">
                        <div id="administrators_filter" class="dataTables_filter"><label>{{ __('ui.role.SearchARole') }}<input
                            type="search" class="form-control form-control-sm" v-model="params.search" placeholder=""
                            aria-controls="administrators"></label></div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-12">
                        <table id="roles"
                               class="table table-bordered table-hover table-striped dataTable dtr-inline table-sm"
                               role="grid" aria-describedby="administrators_info">
                            <thead>
                            <tr role="row">
                                <th class="col-2 sorting" :class="{'sorting_asc': params.field === 'name' && params.direction === 'asc','sorting_desc': params.field === 'name' && params.direction === 'desc' }" tabindex="0"
                                    @click="sort('name')"
                                    aria-controls="roles"
                                    rowspan="1" colspan="1" aria-sort="ascending"
                                    aria-label="Nom: cliquez pour trier par ordre décroissant">
                                    {{ __('ui.administrator.Name') }}
                                </th>
                                <th class="col-2 sorting" :class="{'sorting_asc': params.field === 'slug' && params.direction === 'asc','sorting_desc': params.field === 'slug' && params.direction === 'desc' }" tabindex="0" aria-controls="roles"
                                    @click="sort('slug')"
                                    rowspan="1"
                                    colspan="1"
                                    aria-label="Nom: cliquez pour trier par ordre croissant">
                                    {{ __('ui.role.InternalNameSlug') }}
                                </th>
                                <th class="col-1" tabindex="0" aria-controls="roles" rowspan="1"
                                    colspan="1">{{ __('ui.administrator.Actions') }}
                                </th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr :class="{'table-primary' : props.currentItem === item}" v-for="(item, index) in items.data" :key="item.id">
                                <td class="dtr-control sorting_1" tabindex="0">
                                    {{ __(item.name) }}
                                    <span v-if="!item.can_be_updated" class="fa fa-lock"></span>
                                </td>
                                <td class="dtr-control sorting_1" tabindex="0">{{item.slug}}
                                </td>
                                <td class="text-center">
                                    <button v-if="item.can_be_updated" class="btn btn-default btn-sm mr-1" type="button" @click="editItem(item)"><i class="fas fa-pen"></i></button>
                                    <button v-if="item.can_be_deleted" @click="openSupressModal(item)" type="button" class="btn btn-default btn-sm"><i class="fas fa-trash-alt"></i></button>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-12 col-md-5">
                        <div class="dataTables_info mb-2 mb-sm-0" id="administrators_info" role="status"
                             aria-live="polite">{{ __('ui.administrator.DisplayOf') }} {{ items.from }} {{ __('ui.administrator.To') }} {{ items.to }} {{ __('ui.administrator.On') }}
                            {{ items.total }} {{ items.total > 1 ? __('ui.administrator.Entries') : __('ui.administrator.Entry') }}
                        </div>
                    </div>
                    <div class="col-sm-12 col-md-7">
                        <Pagination @paginate="editItem(null)" class="mt-2 mb-sm-0" id="administrators_paginate" :items="items"/>
                    </div>
                </div>
            </div>
        </div>
    </div>


</template>
