<script setup>
import {Head, Link, useForm, usePage} from '@inertiajs/inertia-vue3';
import BoGuestLayout from '@/Layouts/GuestLayout.vue';
import BoInput from '@/Components/Input.vue';
import BoValidationErrors from '@/Components/ValidationErrors.vue';
import BoLoginBoxTitle from "@/Components/LoginBoxTitle.vue";
import BoCheckbox from "@/Components/Checkbox.vue";
import BoButton from "@/Components/Button.vue";
import {computed} from "vue";
import InputAppend from "@/Components/InputAppend.vue";
//import {IdeleLocalIndexedDB} from "@/Helpers/SynchroData/synchro_data.js";
import {useSynchroLocalData} from "@/services/synchro_data"
const props = defineProps({
    canResetPassword: Boolean,
    canRegister: Boolean,
    status: String,
    test: String,
    translations: String,
});

const form = useForm({
    email: '',
    password: '',
    remember: false
});
const resetPwdLinkCssClass = computed(() => {
    return {
        "mb-0": !props.canRegister,
        "mb-1": props.canRegister,
    };
});
const registerLinkCssClass = computed(() => {
    return {
        "mt-3": !props.canResetPassword,
        'mt-1': props.canResetPassword,
    };
});

const submit = () => {

    form.post(route('login'), {
        onFinish: () => form.reset('password'),
        onSuccess: () => {
            const {synchronizeDataOnLogin} = useSynchroLocalData();
            synchronizeDataOnLogin();
        }
    });
};
</script>

<template>

    <p> {{ translations }}</p>
    <BoGuestLayout>
        <Head title="Connexion"/>
        <div class="login-box">
            <BoLoginBoxTitle/>
            <div class="card">
                <div class="card-body login-card-body">
                    <BoValidationErrors class="mb-4"/>
                    <div v-if="status" class="alert alert-success mb-3" role="alert">
                        {{ status }}
                    </div>
                    <form @submit.prevent="submit">
                        <div class="input-group mb-3">
                            <BoInput id="email" :placeholder="__('ui.login.Email') " type="email" v-model="form.email"
                                     autocomplete="username" required autofocus/>
                            <InputAppend icon="fa-envelope"/>
                        </div>
                        <div class="input-group mb-3">
                            <BoInput id="password" :placeholder="__('ui.login.Password') " type="password"
                                     v-model="form.password" required autocomplete="current-password"/>
                            <InputAppend icon="fa-lock"/>
                        </div>
                        <div class="row">
                            <div class="col-7">
                                <div class="icheck-primary">
                                    <BoCheckbox id="remember_me" name="remember" v-model:checked="form.remember"/>
                                    <label for="remember_me">
                                        {{ __('ui.login.RememberMe') }}
                                    </label>
                                </div>
                            </div>
                            <div class="col-5">
                                <BoButton type="submit"
                                          class="ms-4 btn-block btn-primary"
                                          :class="{ 'text-white-50': form.processing }"
                                          :disabled="form.processing">
                                    <div v-show="form.processing" role="status">
                                        <span class="sr-only">Chargement...</span>
                                    </div>
                                    {{ __('ui.login.Login') }}
                                </BoButton>
                            </div>
                        </div>
                    </form>
                    <p class="mt-3" :class="resetPwdLinkCssClass" v-if="canResetPassword">
                        <Link :href="route('password.request')">
                            {{ __('ui.login.ForgotPassword') }}
                        </Link>
                    </p>
                    <p class="mb-0" :class="registerLinkCssClass" v-if="canRegister">
                        <Link :href="route('register')">
                            Inscription
                        </Link>
                    </p>
                </div>
            </div>
        </div>
    </BoGuestLayout>
</template>
