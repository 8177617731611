<script setup>
import {onMounted, onUnmounted} from "vue";

const props = defineProps({
    bodyClass: {
        type: [String, Boolean],
        default: "login-page"
    }
});

onMounted(() =>{
    if(props.bodyClass){
        document.body.classList.add(props.bodyClass);
    }
});
onUnmounted(()=>{
    if(props.bodyClass){
        document.body.classList.remove(props.bodyClass);
    }
})

</script>

<template>
    <slot />
</template>
