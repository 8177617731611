<script setup>
import BoAuthenticatedLayout from '@/Layouts/AuthenticatedLayout.vue';
import {Head} from '@inertiajs/inertia-vue3';
import List from "@/Pages/Permissions/partials/List.vue";
import {ref} from "vue";
import AddEditToolbox from "@/Pages/Permissions/partials/AddEditToolbox.vue";
import {onEnterOpacity, onBeforeEnterOpacity} from "@/Helpers/animations";


const props = defineProps({
    permissions: {
        type: Object,
    },
});
const itemToEdit = ref(null);
const edit = (item) => {
    itemToEdit.value = item;
}


</script>
<template>
    <Head title="Gestion des permissions"/>

    <BoAuthenticatedLayout>
        <template #page_title>{{ __('ui.mainNavigation.PermissionManagement') }}</template>
        <transition @before-enter="onBeforeEnterOpacity"
                    @enter="onEnterOpacity"
                    appear mode="out-in">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-sm-8">
                        <List :current-item="itemToEdit" @edit-item="edit" />
                    </div>
                    <div class="col-sm-4">
                        <AddEditToolbox :item-to-edit="itemToEdit"
                                        @cancel-editing="edit(null)"/>
                    </div>
                </div>
            </div>
        </transition>
    </BoAuthenticatedLayout>
</template>
