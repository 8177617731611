const flattenAteliers = (ateliers) =>{
    return ateliers.reduce((acc, atelier) => {

        const atelierSansChildren = { ...atelier };
        delete atelierSansChildren.children;
        acc.push(atelierSansChildren);


        if (atelier.children && atelier.children.length > 0  ) {
            acc = acc.concat(flattenAteliers(atelier.children));
        }
        return acc;
    }, []);
}

export {flattenAteliers}
